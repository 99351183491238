/* eslint-disable @typescript-eslint/no-non-null-asserted-optional-chain */
import moment, { Moment } from 'moment';
import { getDateTimeZoneOffset, getIsoDateTime, padZeros } from '../helpers/datetime';
import i18n from '../i18n';
import { ParsedAddress, Timezone } from '../types';
import { AppState, RequestState } from '../types/stores';

export const getIsRequestStateBlocking = (requestStatus: RequestState) => {
  if (requestStatus === RequestState.LOADING) return true;
  if (requestStatus === RequestState.FAILED) return true;
  return false;
};

export const getCentralHealthInputMask = (): RegExp[] => [/[1-9]/, ...Array(11).fill(/[0-9]/)];

export const validateString = (value: string) => value.replace(/[^0-9]/g, '').replaceAll(' ', '');

export const isEven = (value: number): boolean => value % 2 === 0;

export const getCompletedPhoneNumber = (number?: number | string) => (number ? '+1' + number : '');

export const getFormattedPhoneNumber = (phone: string) =>
  phone ? phone.replace(/[^\d]/g, '').replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : '';

export const isAddressValid = ({ street_address, city, state }: ParsedAddress) =>
  street_address && state && city && !isNaN(Number(street_address.split(' ')[0]));

export const isValidDate = (date: any) => !isNaN(date) && date instanceof Date;

export const metersToMiles = (meters?: number) =>
  Math.round(Number(meters) * 0.000621371192 * 10) / 10;

export const secondsToMinutes = (secs?: number) => Math.ceil(Number(secs) / 60);

export const convertDateToUTC = (date: string | Date | Moment) =>
  moment(date).utc(false).toISOString().replace(':00.0', ':');

export const getDateFromDateTime = (datetime: string | Date, timezone: Timezone) => {
  const isoDateTime = getIsoDateTime(new Date(datetime), timezone);
  return moment(isoDateTime).format('YYYY/MM/DD');
}

export const getReturnPickUpTime = (appointmentDate: string | Date, returnTime: string, timezone: Timezone) => {
  const appointmentUtcOffset = getDateTimeZoneOffset(new Date(appointmentDate), timezone);
  const appointmentDateString = moment.utc(appointmentDate).utcOffset(appointmentUtcOffset).format('YYYY/MM/DD');
  const apptDateOnly = getDateFromDateTime(appointmentDateString, timezone);
  const browserReturnDate = new Date(`${apptDateOnly} ${returnTime}`);
  const returnDate = getIsoDateTime(browserReturnDate, timezone);
  const isReturnBeforeAppointment = moment(returnDate).isBefore(appointmentDate);

  if (isReturnBeforeAppointment) {
    return convertDateToUTC(moment(returnDate).add(1, 'day')).toString();
  }

  return convertDateToUTC(returnDate.toString());
};

const getFirstNameFromString = (name: string) => name.split(' ')[0];

const getLastNameFromString = (name: string) => {
  const nameArray = name.split(' ');
  nameArray.shift();

  return nameArray.join(' ');
};

export const getSubmitRequestDataFromState = ({
  memberId,
  healthPlan,
  firstName,
  secondName,
  birthDate,
  email,
  additionalConfirmationEmail,
  isMobile,
  purposeVisit,
  phoneNumber,
  pickUpLocation: {
    city: pickup_city,
    state: pickup_state,
    notes: pickup_notes,
    addressName: pickup_name,
    address1: pickup_address1,
    address2: pickup_address2,
    latitude: pickup_latitude,
    longitude: pickup_longitude,
    zipCode: pickup_postal_code,
    contactNumber: pickup_phone_number,
    contactNumberExt: pickup_phone_extension,
  },
  dropOffLocation: {
    address1: dropoff_address1,
    address2: dropoff_address2,
    addressName: dropoff_name,
    city: dropoff_city,
    state: dropoff_state,
    notes: dropoff_notes,
    zipCode: dropoff_postal_code,
    latitude: dropoff_latitude,
    longitude: dropoff_longitude,
    contactNumber: dropoff_phone_number,
    contactNumberExt: dropoff_phone_extension,
  },
  assistanceInfo: {
    assistance,
    companionName,
    numberOfCompanions,
    companionPhoneNumber,
    companionRelationship,
    isCompanionPhoneNumberMobile,
  },
  roundTripInfo: { willCall, pickUpTime, isOneWay },
  tripEstimation,
  appointmentTime,
  timezone,
}: AppState) => ({
  additional_confirmation_email: additionalConfirmationEmail,
  // member info
  member_external_id: memberId,
  member_plan: healthPlan?.name,
  member_first_name: firstName,
  member_last_name: secondName,
  member_date_of_birth: moment(birthDate).format('YYYY-MM-DD'),
  member_email: email,
  member_phone_number: getCompletedPhoneNumber(phoneNumber),
  member_phone_extension: '', // ??
  member_phone_type: isMobile ? 'mobile' : 'not mobile',
  // pick up info
  pickup_name,
  pickup_phone_number: getCompletedPhoneNumber(pickup_phone_number),
  pickup_phone_extension: pickup_phone_extension || '',
  pickup_phone_type: 'mobile',
  pickup_address1,
  pickup_address2,
  pickup_notes,
  pickup_city,
  pickup_state,
  pickup_postal_code,
  pickup_latitude: pickup_latitude?.toString(),
  pickup_longitude: pickup_longitude?.toString(),
  //drop off info
  dropoff_name,
  dropoff_phone_number: getCompletedPhoneNumber(dropoff_phone_number),
  dropoff_phone_extension,
  dropoff_phone_type: 'mobile',
  dropoff_address1,
  dropoff_notes,
  dropoff_address2,
  dropoff_city,
  dropoff_state,
  dropoff_postal_code,
  dropoff_latitude: dropoff_latitude?.toString(),
  dropoff_longitude: dropoff_longitude?.toString(),
  // assistance info
  companion_first_name: getFirstNameFromString(companionName!),
  companion_last_name: getLastNameFromString(companionName!),
  companion_phone_number: getCompletedPhoneNumber(companionPhoneNumber),
  companion_phone_extension: '', // ??
  companion_phone_type: isCompanionPhoneNumberMobile ? 'mobile' : 'not mobile',
  companion_primary_contact: !!numberOfCompanions,
  companion_relationship: companionRelationship,
  language: i18n.language,
  number_of_companions: numberOfCompanions?.toString(),
  pickup_time: convertDateToUTC(tripEstimation?.pickup_time!),
  appointment_time: convertDateToUTC(appointmentTime),

  // round trip info
  return_is_will_call: !!willCall,
  return_pickup_time: isOneWay || willCall ? '' : getReturnPickUpTime(appointmentTime, pickUpTime!, timezone),
  purpose_alias: purposeVisit.alias,
  type_id: assistance?.id || '1',
  is_round_trip: !isOneWay,
  // timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  timezone: timezone.tzIdentifier,
});
